.experience__container {
    display: grid;
    grid-template-columns: 1fr, 1fr;
    gap: 2rem;
}

.experience__contaier > div {
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
}

.experience__container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.experience__container > div h3 { 
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

.experience__container {
    text-align: center;
    padding-left: 17rem;
    padding-right: 17rem;
    
}

.experience__work > h2, p {
    text-align: center;
    margin-bottom: 2rem;  
}

.experience__programming > ul > h5 {
    color: var(--color-primary);
}