/* Basic formatting for all elements */
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

/* Set some CSS Global Variables */
:root {
    /* Colors */
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);

    --transition: all 400ms ease;

    /* General Container */
    --container-width-large: 75%;
    --container-width-med: 86%;
    --contaier-width-sm: 90%;
}

html {
    scroll-behavior: smooth;
}

/* Remove scroll bar */
::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    /* Background Texture */ 
    /*background-image: url(../src/assets/bg-texture.png);*/
}

/* General Styles */
.container {
    width: var(--container-width-large);
    margin: 0 auto;
}

h1, h2, h3, h4, h5 {
    font-weight: 500;
}

/* All components wrapped in a section */
section {
    margin-top: 8rem;
}

/* h2's and h5's within a section specifically. */
section > h2, section > h5  {
    text-align: center;
    color: var(--color-light);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color : var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}
a:hover {
    color: var(--color-white);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover {
    /* On hover change from primary to transparent */
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* Media Queries: Tablets and such */
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-med);
    }

    section { 
        margin-top: 6rem;
        height: 100vh;
    }
}

/* Media Queries: Phones and such */
@media screen and (max-width: 600px) {
    .container {
        width: var(--contaier-width-sm);
    }

    section > h2 { 
        margin-top: 2rem;
    }
}